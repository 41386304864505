import AppLayout from 'components/AppLayout';
import HeadLayout from 'components/HeadLayout';
import EunoiaSadLogo from 'assets/icons/eunoia-logo-sad.svg';
import styles from './NotFoundPage.module.scss';

export default function NotFoundPage() {
  return (
    <>
      <AppLayout>
        <HeadLayout />
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.logoSad}>
              <EunoiaSadLogo />
              <h2>Oops... </h2>
              <h4>No hemos encontrado la página</h4>
            </div>
            {/* <div className={styles.logoNormal}>
              <EunoiaTitleLogo className={styles.title} />
              <EunoiaLogo />
            </div> */}
          </div>
        </div>
      </AppLayout>
    </>
  );
}
